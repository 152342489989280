export const JSONData = [
  {
   "FirstName": "Prof. Anasuya",
   "LastName": " Ganguly",
  },
  {
   "FirstName": "Prof. Angshuman",
   "LastName": "Sarkar",
  },
  {
   "FirstName": "Prof. Arnab",
   "LastName": "Banerjee",
  },
  {
    "FirstName": "Prof. Dibakar",
    "LastName": "Chakrabarty",
  },
  {
   "FirstName": "Prof. Indrani",
   "LastName": " Talukadar",
  },
  {
   "FirstName": "Prof. Judith",
   "LastName": "Braganca",
  },
  {
   "FirstName": "Prof. Malabika",
   "LastName": "Biswas",
  },
  {
   "FirstName": "Prof. Meenal",
   "LastName": "Kowshik",
  },
  {
   "FirstName": "Prof. Rajesh",
   "LastName": "Mehrotra",
  },
  {
   "FirstName": "Prof. Raviprasad",
   "LastName": "Aduri",
  },
  {
   "FirstName": "Prof. Srikanth",
   "LastName": "Mutnuri",
  },
  {
   "FirstName": "Prof. Sumit",
   "LastName": "Biswas",
  },
  {
   "FirstName": "Prof. Samit",
   "LastName": "Chattopadhyay",
  },
  {
   "FirstName": "Prof. Sandhya",
   "LastName": "Mehrotra",
  },
  {
   "FirstName": "Prof. Sonal",
   "LastName": "Ayakar",
  },
  {
    "FirstName": "Prof. Sukanta",
    "LastName": "Mondal",
   },
   {
    "FirstName": "Prof. Tusar Tirtha",
    "LastName": "Saha",
   },
   {
    "FirstName": "Prof. Veeky",
    "LastName": "Baths",
   },
   {
    "FirstName": "Prof. Vijayshree",
    "LastName": "Nayak",
   },
 
 ]